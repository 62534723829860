/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";

import './Works.css';

// Import ../../assets/recentprojects/
import tradeAssistent from '../../assets/images/tradeAssistent.png';
import ProfitBoost from '../../assets/images/profitBoost.png';
import Lofo from '../../assets/recentprojects/lofo.png';
import Startup from '../../assets/recentprojects/startup.png';
import Lacalle from '../../assets/recentprojects/lacalle.png';

const useStyles = makeStyles((theme) => ({
    main: {
        maxWidth: '100vw',
        marginTop: '3em',
        marginBottom: "auto",
    },
}));

export const Works = () => {
    const classes = useStyles();

    return (
        <section id="works">
            <Container component="main" className={classes.main} maxWidth="md" style={{ textAlign: 'center', margin: '0 auto', padding: '40px 0' }}>
                <h1 className="contact_msg">
                    <TextDecrypt text={"Kits - Estratégias"} />
                </h1>
                <div className="project" style={{ marginBottom: 150 }}>
                    <div className="__img_wrapper">
                        <img src={tradeAssistent} />
                    </div>
                    <div className="__content_wrapper">
                        <h3 className="title">
                            <TextDecrypt text={'Master Kit'} />
                        </h3>
                        <p className="description">
                            <strong>TRADE ASSISTENT</strong>, Trabaja con los Volatilitys, te calcula el riesgo, cantidad de posiciones, lotaje, Stop Loss, Take Profit, además abre y cierra las posiciones por tí.
                            También incluye los <strong>Bots Boom Explotion y Crash Explotion.</strong>.
                        </p>
                    </div>
                </div>

                <div className="project">
                    <div className="__img_wrapper">
                        <img src={ProfitBoost} />
                    </div>
                    <div className="__content_wrapper">
                        <h3 className="title">
                            <TextDecrypt text={'Prime Kit'} />
                        </h3>
                        <p className="description">
                            <strong>PROFIT BOOST</strong>, Trabaja con los Volatilitys, Boom, Crash, DEX, te calcula el riesgo, previsualiza las posiciones, calcula la perdida y ganancia en $ dependiendo de la cantidad de operaciones, abre y cierra posiciones preconfiguradas, trabaja con microlotaje, etc.
                            También incluye los Bots <strong> Trade Assistent, Boom Explotion, Crash Explotion.</strong>.
                        </p>
                    </div>
                </div>
            </Container>
        </section>
    );
};
