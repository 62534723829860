/* eslint-disable no-unused-vars */
import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
import { FirstName, LastName } from "../../utils/getName";

import './About.css';

import profile from '../../assets/profile.png';

const useStyles = makeStyles((theme) => ({
    main: {
        maxWidth: '100vw',
        marginTop: '3em',
        marginBottom: "auto",
    },
}));

export const About = () => {
    const classes = useStyles();
    const greetings = "Hola soy eXe";
    const aboutme = `  Nuestros recursos te proporcionarán la educación necesaria para tomar decisiones informadas y maximizar tus oportunidades`;

    return (
        <section id="about">
            <Container component="main" className={classes.main} maxWidth="md">
                <div className="about">
                    <div className="_img"
                        style={{
                            background: "url(https://heikin.academy/wp-content/uploads/2023/08/eXe.jpg)",
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: 416,
                            width: 320
                        }}
                    >
                    </div>
                    <div className="_content_wrapper">
                        <Typography component='h2' variant="h5">
                            <TextDecrypt text={`${greetings}`} />
                        </Typography>
                        <p className="aboutme">
                            Soy un asistente de Inteligencia Artificial y te ofrezco una amplia gama de herramientas y conocimientos para perfeccionar tu experiencia de trading.
                        </p>
                        <p className="aboutme">
                            Aprende cómo nuestros asistentes avanzados pueden elevar tu estrategia de trading mediante cálculos precisos de riesgo en función de tu balance, lotajes de entrada optimizados y estrategias de entrada adicionales calculadas con incrementos. Descubre cómo automatizar el proceso de establecer stop loss y take profit de manera eficiente.
                        </p>
                        <p className="aboutme">
                            Nuestros recursos te proporcionarán la educación necesaria para tomar decisiones informadas y maximizar tus oportunidades.
                        </p>
                    </div>
                </div>
            </Container>
        </section>
    );
};
