import React, { useState } from 'react';
import { Container, Grid, Modal, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";

const useStyles = makeStyles((theme) => ({
    main: {
        maxWidth: '140vw',
        marginTop: "auto",
        marginBottom: "auto",
    },
    heading: {
        marginLeft: theme.spacing(50),
        "@media (max-width: 768px)": {
            marginLeft: theme.spacing(10),
        },
    },
    jobs: {
        "@media (max-width: 768px)": {
            fontSize: '3rem',
        },
    },
}));

export const VideoGallery = () => {
    const classes = { useStyles }; // Aquí deberías tener tus estilos
    const videos = [
        'WDyyaaHLjzc',
        'PdYFYot-uPU',
        'LA5Ug4rMnMU',
        'hEQRfNddsNk',
        // 'VIDEO_ID_5',
        // 'VIDEO_ID_6',
    ];

    const [open, setOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState('');

    const handleVideoClick = (videoId) => {
        setOpen(true);
        setCurrentVideo(videoId);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentVideo('');
    };

    return (
        <section id="videoGallery" style={{ textAlign: 'center', margin: '0 auto', padding: '40px 0' }}>
            <Container component="main" className={classes.main}>
                <h1 className="contact_msg">
                    <TextDecrypt text={"Videos"} />
                </h1>
                <Grid container spacing={3} justifyContent="center">
                    {videos.map((videoId, index) => (
                        <Grid item xs={12} sm={6} key={videoId}>
                            <div
                                onClick={() => handleVideoClick(videoId)}
                                style={{
                                    background: `url('https://img.youtube.com/vi/${videoId}/0.jpg')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    cursor: 'pointer',
                                    height: '300px',
                                    borderRadius: 30,
                                }}
                            />
                            {index % 2 === 1 && <div style={{ width: '100%', height: '20px' }} />}
                        </Grid>
                    ))}
                </Grid>
                <Modal open={open} onClose={handleClose} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ backgroundColor: '', padding: '20px' }}>
                        {currentVideo && (
                            <iframe
                                title="YouTube Video"
                                width="800"
                                height="450"
                                src={`https://www.youtube.com/embed/${currentVideo}`}
                                allowFullScreen
                            />
                        )}
                    </div>
                </Modal>
            </Container>
        </section>
    );
}
