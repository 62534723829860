/* eslint-disable no-unused-vars */
import React from "react";
import { useRef, useEffect } from "react";
import { Container, Typography, TextField, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
import axios from 'axios';

import emailjs from '@emailjs/browser';

import './Contact.css';

const useStyles = makeStyles((theme) => ({
    main: {
        maxWidth: '100vw',
        marginTop: '3em',
        marginBottom: "3em",
    },
    form: {
        width: '100%',
    },
    formfield: {
        width: '100%',
        marginBottom: '2rem',
    },
}));



export const Contact = () => {
    const classes = useStyles();
    const greetings = "Prueba gratis los bots por 15 días";
    const [message, setMessage] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [showLinks, setShowLinks] = React.useState(false);
    const [referralCode, setReferralCode] = React.useState("");

    const form = useRef();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const codeFromURL = urlParams.get("ref");
        if (codeFromURL) {
            console.log("REF", codeFromURL);
            setReferralCode(codeFromURL);
        }
    }, []);

    const register = async (event) => {
        event.preventDefault();

        const formData = new FormData(form.current);

        const requestData = {
            user: formData.get('name'),
            email: formData.get('email'),
            demoID: formData.get('demoID'),
            accountID: formData.get('realID'),
            referral: referralCode,
        };

        try {
            const response = await axios.post('https://mitserver.app:3031/demoActivation', requestData);
            console.log(response.data);
            var status1 = response.data.status;
            var message1 = response.data.message;
            setStatus(status1);
            setMessage(message1);
            if (status1 == 'ok') {
                //setShowLinks(true);
            }

        } catch (error) {
            console.error(error);
        }
        event.target.reset()
    };



    return (
        <section id="contact">
            <Container component="main" className={classes.main} maxWidth="md" style={{ textAlign: 'center', margin: '0 auto', padding: '40px 0' }}>
                <h1 className="contact_msg">
                    <TextDecrypt text={"Registro"} />
                </h1>
                <div className="contact" >
                    <div className="_form_wrapper" style={{ height: "30%" }}>
                        <form ref={form} onSubmit={register} className={classes.form}>
                            <TextField
                                id="outlined-name-input"
                                label="Nombre Completo"
                                type="text"
                                size="small"
                                variant="filled"
                                name="name"
                                className={classes.formfield}
                            />
                            <TextField
                                id="outlined-password-input"
                                label="Email"
                                type="email"
                                size="small"
                                variant="filled"
                                name="email"
                                className={classes.formfield}
                            />
                            <TextField
                                id="outlined-password-input"
                                label="Demo Deriv ID"
                                type="text"
                                size="small"
                                variant="filled"
                                name="demoID"
                                className={classes.formfield}
                            />
                            <TextField
                                id="outlined-password-input"
                                label="Real Deriv ID"
                                type="text"
                                size="small"
                                variant="filled"
                                name="realID"
                                className={classes.formfield}
                            />
                            <button type="submit" value="Send" className="submit-btn">
                                <i className="fas fa-terminal"></i>
                                <Typography component='span'> Registrar</Typography>
                            </button>
                            <div style={{ marginTop: 20 }}>
                                <Typography style={{ color: status == "error" ? "red" : "green" }} component='span'> {message} </Typography>
                            </div>
                            {showLinks && (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                                    <a href={"https://heikin.academy/ProfitBoost.ex5"} download className="download-link">
                                        Descargar ProfitBoost
                                    </a>
                                    <a href={"https://heikin.academy/TradeAssistent.ex5"} download className="download-link">
                                        Descargar TradeAssistent
                                    </a>
                                </div>
                            )}
                        </form>
                    </div>
                    <h6 className="contact_msg">
                        <TextDecrypt text={greetings} />
                    </h6>
                </div>
            </Container>
        </section>
    );
};
