import React from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "./TextDecrypt";
import Resume from "../../settings/resume.json";
import { FirstName, LastName } from "../../utils/getName";

const useStyles = makeStyles((theme) => ({
    main: {
        maxWidth: '140vw',
        marginTop: "auto",
        marginBottom: "auto",
    },
    heading: {
        marginLeft: theme.spacing(50),
        "@media (max-width: 768px)": {
            marginLeft: theme.spacing(10),
        },
    },
    jobs: {
        "@media (max-width: 768px)": {
            fontSize: '3rem',
        },
    },
}));

export const Content = () => {
    const classes = useStyles();

    return (
        <Container component="main" className={classes.main} maxWidth="md" style={{ paddingTop: 150, marginBottom: 100 }}>
            <div className={classes.heading}>
                <Typography variant="h5" component="h2">
                    <TextDecrypt text={"Transformando Datos en Decisiones Rentables"} />
                </Typography>
                <Typography variant="h1" component="h1" className={classes.jobs}>
                    <TextDecrypt text={"RoboTrade"} />
                    <TextDecrypt text={"Hub"} />
                </Typography>
            </div>
        </Container>
    );
};
