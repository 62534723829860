import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    svgHover: {
        fill: theme.palette.foreground.default,
        "&:hover": {
            fill: theme.palette.primary.main,
        },
        transition: "all 0.5s ease",
    },
}));

export const Logo = () => {
    const classes = useStyles();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            className={classes.svgHover}
        >
            <text x="50%" y="50%" font-size="50" font-family="Cursive" font-weight="700" fill="#000" text-anchor="middle" alignment-baseline="middle">
                RT
            </text>
        </svg>
    );
};
